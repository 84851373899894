export const listData = {
    drafrList : [
        {
            name: "组名",
            text: "groupName",
        },
        {
            name: "小组人数",
            text: "columsInfo",
        },
        {
            name: "小组状态",
            text: "columsStatus",
        },
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    classId: 0,
    loading: true,
    spreadTable:[],
    tableHeight: 400,
    operation: ['加入'],
    pageType: 1,
    lastState: 1000,

    userInput: undefined,

    showRoleDia: false,
    userItem:{},
    userValue:'',
    userOptions:[],
    itemList:[],
    parame:{}
}
